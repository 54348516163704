<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="460"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>考核通知单确认
      </span>
    </div>
    <p class="info">
      请确定已完成考核通知单的核对，后续将根据其明细做政策规则处罚，请知悉。
    </p>
  </a-modal>
</template>

<script>
export default {
  name: "conform-modal",
  data() {
    return {
      ModalText: "Content of the modal",
      //   visible: false,
      confirmLoading: false,
      info: "222"
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // this.cancelReaList()
  },
  methods: {
    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("confirm", this.info);
    },
    //  handleOk(){
    //   // this.$emit('update:visible',false)
    //   this.$emit('cancelReasonHandOk',this.reason,this.FXDLlnum)
    //   this.textarea = ''
    //   this.form.resetFields();

    // },
    cancle() {
      this.$emit("update:visible", false);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      //   padding: 30px 0px 0 40px;
      padding-bottom: 80px;
      padding-left: 30px;
      padding-right: 30px;
      .cancelContent {
        text-align: left;
        padding: 0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .ant-tree-node-selected {
          background-color: red;
        }
        .ant-select-auto-complete.ant-select .ant-input {
          width: 400px;
        }
        .ant-form {
          line-height: 32px;
        }
        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }
        .ant-form-item-control {
          line-height: 32px;
        }
        .ant-form-item-label {
          line-height: 32px;
        }
        .ant-form-item {
          margin-bottom: 0;
        }
        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }
      .cancelInfo {
        padding: 24px 0 50px 0px;
        text-align: left;
        overflow: hidden;
        .cancleZi {
          width: 100px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }
        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper {
          width: 400px;
        }
      }
      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #e4f8f7;
        .modal_close_icon {
          color: #08aba8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom: 34px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #e4f8f7;
            z-index: -10;
          }
        }
      }
      .info {
        text-align: left;
        margin-bottom: 80px;
        font-size: 18px;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
        margin: 0 auto;
        width: 400px;
      }
    }
    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;
      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }
      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
        margin-left: 24px;
      }
    }
  }
}
</style>


