<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="808"
    :closable="false"
    centered
    :maskClosable="false"
    :footer="null"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>考核通知单申诉流程图
      </span>
    </div>
    <div class="info">
      <div class="wrap">
        <div class="pur-top-right-icon">
          <i
            class="iconfont icon-kaishi nocir"
            :class="[currentIndex >= 1 ? 'i-green' : 'i-gray']"
            style="margin-right:-6px"
          />
          <span :class="[currentIndex > 1 ? 'green' : 'gray']"></span>
          <span
            class="iconfont icon-jiantou nocir jiantou"
            :class="[currentIndex > 1 ? 'i-green' : 'i-gray']"
          />
          <i
            class="iconfont icon-shangjiafaqi"
            :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
            style="margin-left: 17px;"
          />
          <span :class="[currentIndex > 2 ? 'green' : 'gray']"></span>
          <span
            class="iconfont icon-jiantou nocir jiantou"
            :class="[currentIndex > 2 ? 'i-green' : 'i-gray']"
          />
          <i
            class="iconfont icon-fazhanbuchang"
            :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
            style="margin-left: 17px;"
          />

          <span :class="[currentIndex > 3 ? 'green' : 'gray']"></span>
          <span
            class="iconfont icon-jiantou nocir jiantou"
            :class="[currentIndex > 3 ? 'i-green' : 'i-gray']"
          />
          <i
            class="iconfont icon-yingxiaozongjingli"
            :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
            style="margin-left: 17px;"
          />
          <span :class="[currentIndex > 4 ? 'green' : 'gray']"></span>
          <span
            class="iconfont icon-jiantou nocir jiantou"
            :class="[currentIndex > 4 ? 'i-green' : 'i-gray']"
          />
          <i
            class="iconfont icon-yingxiaozongjingli"
            :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
            style="margin-left: 17px;"
          />
          <span :class="[currentIndex > 5 ? 'green' : 'gray']"></span>
          <span
            class="iconfont icon-jiantou nocir jiantou"
            :class="[currentIndex > 5 ? 'i-green' : 'i-gray']"
          />
          <i
            class="iconfont icon-jieshu-1 nocir"
            :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
            style="margin-left: 11px;"
          />
        </div>
        <div class="pur-top-right-txt">
          <div>
            <!-- [currentIndex >= 5 ? 'i-green' : 'i-gray'] -->
            <p
              style="margin-right:100px;margin-left:5px"
              :class="currentIndex >= 1 ? 'aaaP' : 'HP'"
            >
              开始
            </p>
          </div>

          <div>
            <p
              style="margin-right:50px"
              :class="currentIndex >= 2 ? 'aaaP' : 'HP'"
            >
              商家发起
            </p>
          </div>
          <div>
            <p
              style="margin-right:30px"
              :class="currentIndex >= 3 ? 'aaaP' : 'HP'"
            >
              营销中心客户发展部长
            </p>
          </div>
          <div>
            <p
              style="margin-right:34px"
              :class="currentIndex >= 4 ? 'aaaP' : 'HP'"
            >
              营销中心总经理
            </p>
          </div>
          <div>
            <p
              style="margin-right:44px"
              :class="currentIndex >= 5 ? 'aaaP' : 'HP'"
            >
              中国区营销总部客户发展部
            </p>
          </div>
          <div>
            <p :class="currentIndex >= 5 ? 'aaaP' : 'HP'">结束</p>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
 
<script>
import moment from "moment";
moment.locale("zh-cn");
export default {
  name: "view-process",
  data() {
    return {
      statusObj: {},
      CANCEL: false,
      ModalText: "Content of the modal",
      //   visible: false,
      confirmLoading: false,
      info: "222",
      orderdetail: {
        orderHeader: {}
      }
      // currentIndex: 1
    };
  },
  props: {
    status: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentIndex: function() {
      // START(开始) LAUNCH(发起) YXZX_KHFZB(客户发展部) YXZX_OC(营销中心经理) END(结束)
      let step = 0;
      if (this.status) {
        const status = this.status;
        if (status == "START") {
          step = 1;
        }
        if (status == "LAUNCH") {
          step = 2;
        }
        if (status == "YXZX_KHFZB") {
          step = 3;
        }
        if (status == "YXZX_OC") {
          step = 4;
        }
        if (status == "YXZB_KHFZB_SSSH") {
          step = 5;
        }
        if (status == "END") {
          step = 6;
        }
        return step;
      } else {
        return step;
      }
    }
  },
  mounted() {},
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("confirm", this.info);
    },
    //  handleOk(){
    //   // this.$emit('update:visible',false)
    //   this.$emit('cancelReasonHandOk',this.reason,this.FXDLlnum)
    //   this.textarea = ''
    //   this.form.resetFields();

    // },
    cancle() {
      this.$emit("update:visible", false);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      //   padding: 30px 0px 0 40px;
      padding-bottom: 80px;
      padding-left: 30px;
      padding-right: 30px;
      .cancelContent {
        text-align: left;
        padding: 0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .ant-tree-node-selected {
          background-color: red;
        }
        .ant-select-auto-complete.ant-select .ant-input {
          width: 400px;
        }
        .ant-form {
          line-height: 32px;
        }
        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }
        .ant-form-item-control {
          line-height: 32px;
        }
        .ant-form-item-label {
          line-height: 32px;
        }
        .ant-form-item {
          margin-bottom: 0;
        }
        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }
      .cancelInfo {
        padding: 24px 0 50px 0px;
        text-align: left;
        overflow: hidden;
        .cancleZi {
          width: 100px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }
        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper {
          width: 400px;
        }
      }
      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #e4f8f7;
        .modal_close_icon {
          color: #08aba8;
        }
      }
      .modal_title_box {
        width: 750px;
        text-align: center;
        padding-bottom: 34px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #e4f8f7;
            z-index: -10;
          }
        }
      }
      .info {
        text-align: left;
        margin-bottom: 80px;
        font-size: 18px;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
        margin: 0 auto;
        width: 760px;
        .wrap {
          width: 760px;
          // height: 139px;
          background: #fff;

          .pur-top-right-icon {
            text-align: left;
            // padding-left: 70px;

            .iconfont {
              border-radius: 50%;
              width: 35px;
              height: 35px;
              line-height: 35px;
              font-size: 21px;
              position: relative;
              top: 8px;
              text-align: center;
            }

            .i-green {
              color: #00aaa6;
              border: 1.5px solid #00aaa6;
            }

            .i-gray {
              color: #ccc;
              border: 1.5px solid #ccc;
            }
            .nocir {
              border: none;
            }
            .jiantou {
              // font-size: 14px;
              // width: 7px;
              // height: 10px;
              // margin-right: 10px;
              font-size: 14px;
              width: 7px;
              height: 10px;
              margin-right: 10px;
              /* margin-top: -63px; */
              position: absolute;
              top: 90px;
              margin-left: 0;
            }
            img {
              width: 36px;
              height: 36px;
            }

            span {
              width: 80px;
              margin-left: 6px;
            }

            .green {
              border-bottom: 1px dashed #00aaa6;
            }

            .gray {
              border-bottom: 1px dashed #cccccc;
            }
          }

          .pur-top-right-txt {
            margin: 12px 0 0 0px;
            // :nth-child(1){
            // }
            :nth-child(5) {
              margin-right: 0px;
            }

            div {
              float: left;
              // width: 55px;
              // margin: 0px 74px 0 0;
              // text-align: center;
              // margin-left: -9px;

              .aaaP {
                font-size: 12px;
                font-weight: 500;
                color: #262626;
                line-height: 12px;
                margin-bottom: 7px;
                margin-top: 4px;
              }
              .HP {
                font-size: 12px;
                font-weight: 500;
                color: #aaaaaa;
                line-height: 12px;
                margin-bottom: 7px;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;
      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }
      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
        margin-left: 24px;
      }
    }
  }
}
</style>


