import http from "@/utils/request";

// 获取列表
export function getList(data) {
    return http({
        method: "post",
        url: "/fast/cust/custAssessNotice/page.nd",
        data,
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
    });
}

// 导出

export function getExport(data) {
    return http({
        url: '/custAgentActivity/exportActivityList.nd',
        method: 'get',
        params: data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob'
    })
}

//  确认
export function confirm(data) {
    return http({
        method: "get",
        url: "/custAssessNotice/confirm/"+data+'.nd',
        // data
    });
}

export function appeal(data) {
    return http({
        method: "post",
        url: "/custAssessNotice/appealSum.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}
// 获取未确认数据条数
export function getNotConfirmCount(data) {
    return http({
        method: "get",
        url: "/custAssessNotice/getNotConfirmCount/"+data+'.nd',
        data
    });
}