<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="648"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>考核通知单申诉
      </span>
    </div>
    <div class="choseBox">
      <div class="xialamenu" v-if="type == '市场跑动'">
        <div class="container">
          <span style="color:#D9161C">*</span>申诉原因：
        </div>
        <a-select
          style="width:300px!important"
          class="chose-account"
          option-filter-prop="children"
          :filter-option="filterOption"
          @change="handleChange1"
          show-search
          v-model="reasonId"
        >
          <a-select-option
            v-for="item in reason"
            :key="item.code"
            :value="item.name"
          >{{ item.name }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="xialamenu">
        <div class="container">
          <span style="color:#D9161C">*</span>申诉减免缺口：
        </div>
        <a-input-number
          class="chose-account"
          style="width:300px!important;"
          placehoder=""
          v-model="reduceGap"
          @change="changeNum"
          :min="0"
          :max="this.differenceCount"
        ></a-input-number>
      </div>
      <div style="margin-left:-55px;margin-top:8px">
        <span
          style="font-size: 12px;
          font-weight:400;
          color: #AAAAAA;
          "
        >申诉后缺口：<span style="color:#DA191F"
        >{{ differenceCount1 }}个</span
        ></span
        ><span
        style="font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          margin-left:40px
          "
      >申诉后考核金额：<span style="color:#DA191F"
      >{{assessMoney1? (assessMoney1).toFixed(1):'' }}元</span
      ></span
      >
      </div>
      <div class="xialamenu" v-if="showReason">
        <span class="container" style="margin-top:-68px!important">
          <span v-if="type !== '市场跑动'" style="color:#D9161C!important">*</span
          >原因说明：</span
        >
        <a-textarea
          class="textareaInput"
          style="width:400px!important"
          :auto-size="{ minRows: 4, maxRows: 4 }"
          placeholder="请输入原因说明"
          v-model="textarea"
        />
      </div>
      <span  style="margin-left: 40px;margin-top: 6px;color:#AAAAAA;!important;font-size: 12px">请详细说明减免原因，比如：缺口门店、未跑动原因、未动销原因等。</span>
      <div class="xialamenu">
        <span class="container">证明文件：</span>
        <a-upload
          list-type="picture"
          accept="image/*"
          class="upload"
          name="file1"
          :data="{ fileType: 'personPic' }"
          :action="uploadUrl"
          :file-list="fileList"
          @change="handleChange2"
        >
          <!--          <a-button v-if="fileList.length < 3" class="upLoadButton" style="">-->
          <!--            选择文件-->
          <!--          </a-button>-->
          <a-button
            v-if="fileList.length < 3" class="upLoadButton"
            style="width:88px;height:88px;background:#FAFAFA;border:none;border:1px dashed #D9D9D9"
          >
            <a-icon style="font-size:26px;color:#aaa" type="plus"
            />
          </a-button>
        </a-upload>
      </div>

      <div class="fileListBox">
        <div
          class="fileListItem"
          v-for="(item, index) in fileList"
          :key="item.id"
        >
          <div class="boxbox">
            <p class="filename">{{ item.name }}</p>
            <i
              @click="handleRemove(index)"
              class="iconfont icon-fujianshanchu"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import axios from "axios";
import { publicPath } from "@/common/constant.js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "appeal-modal",
  data() {
    return {
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      ModalText: "Content of the modal",
      //   visible: false,
      confirmLoading: false,
      reasonId: "",
      reduceGap: "", // 申诉减免缺口
      textarea: "",
      reason: [
        // 不合作未在CIS系统冻结、重复门店、新建店未开业、其他；其他类型不显示申诉原因。
        {
          code: 1,
          name: "不合作未在CIS系统冻结"
        },
        {
          code: 2,
          name: "重复门店"
        },
        {
          code: 3,
          name: "新建店未开业"
        },
        {
          code: 4,
          name: "其他"
        }
      ],
      fileList: [],
      fileIds: [],
      showReason: true,
      assessMoney1: 0,
      differenceCount1: 0
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    assessMoney: {
      type: Number
    },
    differenceCount: {
      type: Number
    },
    type: {
      type: String
    }
  },
  watch: {
    assessMoney: function() {
      this.assessMoney1 = this.assessMoney;
    },
    differenceCount: function() {
      this.differenceCount1 = this.differenceCount;
    }
  },

  methods: {
    changeNum(e) {
      let price = 0;
      price = this.assessMoney / this.differenceCount;
      this.assessMoney1 = this.assessMoney - price * e;
      this.differenceCount1 = this.differenceCount - e;
    },
    //上传状态改变
    handleChange2(info) {
      this.fileList = info.fileList;
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          this.fileList = info.fileList;
          this.fileIds.push({
            id: info.file.response.businessId
          });
        } else {
          this.$message.warning("上传失败，请联系管理员");
        }
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file", file);
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { "content-type": "multipart/form-data" }
      };
      // 发送请求
      const BASE_API = publicPath;
      axios
        .post(BASE_API + "/custAgentActivity/uploadFile.nd", formData, config)
        .then(res => {
          if (res.data.message == "上传成功") {
            this.fileIds.push({
              id: res.data.businessId
            });
          } else {
            this.$message.warning("上传失败", res.data.message);
          }
        });
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1);
    },

    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      }
    },
    handleChange1(e) {
      this.reasonId = e;
      // if (this.reasonId == "其他") {
      //   this.showReason = false;
      // } else {
      //   this.showReason = true;
      // }
    },
    handleOk() {
      // if (this.type == "市场跑动" && !this.reasonId) {
      //   this.$message.warning("申诉原因不能为空，请重新填写");
      //   return;
      // }
      if (this.type == "市场跑动" && !this.reduceGap) {
        this.$message.warning("申诉减免缺口不能为空，请重新填写");
        return;
      }

      if (this.type !== "市场跑动" && !this.reduceGap) {
        this.$message.warning("申诉减免缺口不能为空，请重新填写");
        return;
      }
      if (this.type !== "市场跑动" && !this.textarea) {
        this.$message.warning("原因说明不能为空，请重新填写");
        return;
      }
      this.$emit("update:visible", false);
      this.$emit(
        "confirm",
        this.reasonId,
        Number(this.reduceGap),
        this.textarea,
        this.fileIds
      );
      this.reasonId = "";
      this.reduceGap = "";
      this.textarea = "";
      this.fileIds = [];
      this.fileList = [];
      this.assessMoney1 = this.assessMoney;
      this.differenceCount1 = this.differenceCount;
    },
    //  handleOk(){
    //   // this.$emit('update:visible',false)
    //   this.$emit('cancelReasonHandOk',this.reason,this.FXDLlnum)
    //   this.textarea = ''
    //   this.form.resetFields();

    // },
    cancle() {
      this.$emit("update:visible", false);
      this.reasonId = "";
      this.reduceGap = "";
      this.textarea = "";
      this.fileIds = [];
      this.fileList = [];
      this.assessMoney1 = this.assessMoney;
      this.differenceCount1 = this.differenceCount;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .ant-upload-list {
  display: none !important;
}

/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      //   padding: 30px 0px 0 40px;
      padding-bottom: 24px;
      padding-left: 30px;
      padding-right: 30px;

      .cancelContent {
        text-align: left;
        padding: 0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;

        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-select-auto-complete.ant-select .ant-input {
          width: 300px;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .cancelInfo {
        padding: 24px 0 50px 0px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 100px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }

        .ant-input-number {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #e4f8f7;

        .modal_close_icon {
          color: #08aba8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 34px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #e4f8f7;
            z-index: -10;
          }
        }
      }

      .info {
        text-align: left;
        margin-bottom: 80px;
        font-size: 18px;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
        margin: 0 auto;
        width: 400px;
      }

      .choseBox {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-left: 10px;

        /deep/ .ant-upload-list {
          display: none !important;
        }

        .xialamenu {
          /deep/ .ant-upload-list {
            display: none !important;
          }

          display: flex;
          align-items: center;
          flex-direction: row;
          margin-top: 24px;

          /deep/ .ant-input[disabled] {
            color: #fff;
            background-color: #ffffff;
            cursor: not-allowed;
            opacity: 1;
          }

          .textareaInput {
            width: 300px !important;
          }

          .fileText {
            width: 300px !important;
          }

          /deep/ .ant-input .ant-input-disabled {
            color: #fff;
            background-color: #ccc;
            cursor: not-allowed;
            opacity: 1
          }

          .container {
            width: 106px;
            text-align: right;
            font-size: 14px;
            margin-right: 16px;
          }


          .upload {

            /deep/ .ant-upload-list {
              display: none !important;
            }

            .upLoadButton {

              border-right: none;
              color: #777777;
              font-size: 14px;
              height: 22px;
              line-height: 22px;
              text-align: center;
              background: #f7f7f7;
              border-radius: 2px;
              border: none;
              align-items: center;
              background: #f7f7f7;
            }

            .ant-btn {
              align-items: center;
            }

            .ant-btn:hover,
            .ant-btn:focus {
              color: #d9d9d9;
              background-color: #f7f7f7;
              border-color: #d9d9d9;
            }

            /deep/ .ant-btn:hover {
              color: #777777;
              background-color: none;
              border-color: none;
            }

            /deep/ .ant-btn:focus {
              color: #777777;
              background-color: none;
              border-color: none;
            }
          }

          /deep/ .ant-input-number {
            width: 300px !important;
          }

          /deep/ .ant-select-selection {
            width: 300px !important;
          }

          /deep/ .ant-select-selection--single {
            width: 300px !important;
          }

          /deep/ .ant-input {
            width: 300px;
          }
        }

        /deep/ .ant-upload-list {
          display: none !important;
        }

        .fileListBox {
          .fileListItem {
            .boxbox {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 123px;

              p {
                color: chartreuse;
                color: #1890ff;
                font-size: 14px;
              }

              i {
                cursor: pointer;
                margin-left: 8px;
                color: #1890ff;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
        margin-left: 24px;
      }
    }
  }
}
</style>


